<template>
  <div class="gift-record-list-wrap">
    <el-table
      ref="listTable"
      :data="getStoreGiftRecord"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="event_id" label="活動ID" width="130" />
      <el-table-column prop="event_name" label="活動名稱" min-width="180" :show-overflow-tooltip="true" />
      <el-table-column prop="shop_name" label="門市" width="180" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{ scope.row.shop_erp_id }} {{ scope.row.shop_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="redeem_date" label="兌換日期" width="150">
        <template #default="scope">
          <span v-if="scope.row.redeem_date != ''">
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.redeem_date) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="redeem_id" label="交易序號" width="130" />
      <el-table-column prop="member_id" label="會員/旺卡 ID" width="130" />
      <el-table-column prop="customer_type" label="消費者類型" width="130">
        <template #default="scope">
          <span>{{ consumer_options.filter((data) => data.value == scope.row.customer_type)[0].label }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gift_info" label="兌換商名稱（數量）" width="180">
        <template #default="scope">
          <span v-for="(value, key, index) in scope.row.gift_info" :key="'gift' + index">
            {{ key + '(' + value + ')' }}
            {{ Object.keys(scope.row.gift_info).length == index + 1 ? '' : '、' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="redeem_status" label="兌換情況" width="160">
        <template #default="scope">
          <span>{{ redeem_status_options.filter((data) => data.value == scope.row.redeem_status)[0].label }}</span>
          <span class="cancel-text" @click="cancelRedemption({ redeem_id: scope.row.redeem_id, id: scope.row.id })">
            {{ scope.row.redeem_status == 2 ? '取消兌換' : '' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="cancel_time" label="取消兌換時間" width="150">
        <template #default="scope">
          <span v-if="scope.row.cancel_time != ''">
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.cancel_time) }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @click="handleCurrentChange"
    />
  </div>
</template>
<script>
import { ref, getCurrentInstance, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { getFormatDate, formatDateTime } from '@/utils/datetime';
import { consumer_options, redeem_status_options } from '@/utils/define';
export default {
  name: 'store-gift-record-list',
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { getPageSize, getPageTotal, getSearchCondition, getStoreGiftRecord } = useGetters('storeGiftRecord', [
      'getPageSize',
      'getPageTotal',
      'getSearchCondition',
      'getStoreGiftRecord',
    ]);
    const SET_SEARCH_CONDITION = useMutations('storeGiftRecord', ['SET_SEARCH_CONDITION']);
    const doGetStoreGiftRecord = useActions('storeGiftRecord', ['doGetStoreGiftRecord']);

    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      event_id: '',
      event_name: '',
      shop_id: '',
      redeem_id: '', // 交易序號
      member_id: '', // 會員or旺卡 ID
      customer_type: '',
      gift: '',
      redeem_status: '',
    };
    router.push({ query, replace: true }).then(async () => {
      SET_SEARCH_CONDITION(searchDataInit);
      await doGetStoreGiftRecord({
        start: (currentPage.value - 1) * getPageSize.value,
        ...searchDataInit,
      });
    });

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //更新資料
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          await doGetStoreGiftRecord({
            start: (currentPage.value - 1) * getPageSize.value,
            ...getSearchCondition.value,
          });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //取消兌換
    const doCancelStoreGift = useActions('storeGiftRecord', ['doCancelStoreGift']);
    const cancelRedemption = async (data) => {
      await doCancelStoreGift(data).then(async (res) => {
        if (res == 'success') {
          await doGetStoreGiftRecord({
            start: (currentPage.value - 1) * getPageSize.value,
            ...getSearchCondition.value,
          });
          proxy.$message({
            type: 'success',
            message: '已取消兌換',
          });
        }
      });
    };

    return {
      formatDateTime,
      consumer_options,
      redeem_status_options,
      getStoreGiftRecord,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      handleCurrentChange,
      cancelRedemption,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.gift-record-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.cancel-text {
  color: $color-green;
  text-decoration: underline;
  margin-left: 16px;
  cursor: pointer;
}
</style>
